#mouth.elon_musk {
    display: block;
    margin-top: 30px;
    position: absolute;
    transform-origin: 0 100%;

    height: inherit;
    align-content: flex-end;
}

#mouth.elon_musk .front.elon_musk {
    position: relative;
    z-index: 3;
    height: 565px;
    width: 347px;
    left: -140px;
    bottom: 0;
}

#mouth.elon_musk .tongue.elon_musk {
    top: 267px;
    left: 100px;
    z-index: 2;
    width: 58px;
    height: 36px;
}

#draggable-prop-container.elon_musk {
    display: block;
    margin-left: 212px;
    position: relative;
    top: 0;
    left: 0;
    height: 700px;
    z-index: 10
}

#draggable-prop-child.elon_musk  {
    position: absolute;
    bottom: 210px;
    right: 80px;
    width: 120px;
    height: 170px;
    z-index: 1
}

#draggable-prop-child.elon_musk .draggable-prop.elon_musk {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1
}

#draggable-prop-child.elon_musk .handle.elon_musk {
    background: transparent;
    display: block;
    position: absolute;
    bottom: 0%;
    right: 6%;
    width: 93%;
    height: 100%;
    z-index: 5;
    transform-origin: 100% 100%;
    transform: none;
}

#draggable-prop-child.elon_musk .handle.elon_musk .grip {
    background: transparent;
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 9
}

#draggable-prop-child.elon_musk .handle.elon_musk .mask {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 50%;
    z-index: 10
}

.licky_elon_musk,
.lickylicky_elon_musk,
.lickylickylicky_elon_musk {
    transform-origin: 0 0;
    animation-name: yumyum_elon_musk;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out
}

.licky_elon_musk {
    animation-duration: 1s
}

.lickylicky_elon_musk {
    animation-duration: .3s
}

.lickylickylicky_elon_musk {
    animation-duration: .1s
}

@keyframes yumyum_elon_musk {
    0% {
        left: 100px;
        top: 267px
    }

    50% {
        left: 75px;
        top: 267px;
    }

    100% {
        left: 100px;
        top: 267px
    }
}

@keyframes yumyum_elon_musk_mobile {
    0% {
        left: 72px;
        top: 110px
    }

    50% {
        left: 62px
    }

    100% {
        left: 72px;
        top: 110px
    }
}

.desc-title.elon_musk {
    font-family: Ambit;
    font-size: 98px;
    font-style: normal;
    font-weight: 700;
    line-height: 0.95;
    letter-spacing: 4.9px;

    background: linear-gradient(91deg, #F1AFBC 0.4%, #FF9EB1 99.56%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (max-width: 1100px) {
    .desc-title.elon_musk {
        font-size: 70px;
        letter-spacing: 1px;
        /* line-height: ; */
    }
}

@media (max-width: 500px) {
    .desc-title.elon_musk {
        font-size: 40px;
        letter-spacing: 1px;
    }
}

div#\#relpto.elon_musk {
    width: 140px;
}

@media (max-width: 900px) {
    .this_btm_text {
        display: none;
    }

    #draggable-prop-child.elon_musk {
        width: 55px;
        height: 90px;
        bottom: 15px;
        left: 90px;
    }

    #draggable-prop-container.elon_musk {
        margin-left: 106px;
        height: 300px
    }
    
    #mouth.elon_musk {
        margin-top: 10px;
    }

    #mouth.elon_musk .front.elon_musk {
        height: 400px;
        width: 300px;

        left: -30px;

        height: inherit;
        top: 0px;
    }

    #mouth.elon_musk .tongue.elon_musk {
        width: 20px;
        left: 72px;
        top: 110px;
        height: 12.5px;
    }

    .licky_elon_musk,
    .lickylicky_elon_musk,
    .lickylickylicky_elon_musk {
        transform-origin: 0 0;
        animation-name: yumyum_elon_musk_mobile;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out
    }
}