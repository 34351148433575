.container {
    width: 100vw;
    height: 100vh;
    position: relative;

    /* background: #FEBFCD; */
    /* transition: all 0.3s ease-in-out; */
    overflow: hidden;
}

/* .bg_1 {
    width: 100vw;
    height: 100%;
    background-color: #FEBFCD;
}

.bg_2 {
    width: 100vw;
    height: 100%;
    background-color: #AD648E;
}

.bg_3 {
    width: 100vw;
    height: 100%;
    background-color: #390031;
} */

.bg_1 {
    background-color: #390031;
}

.bg_1 h1 {
    color: #03F2FF;
}

.bg_2 {
    background-color: #AD648E;
}

.bg_2 h1 {
    color: #FF8ACE;
}

.bg_3 {
    background-color: #FEBFCD;
}

.bg_3 h1 {
    color: #EF2A64;
}

.update_bg {
    width: 100vw;
    height: 100%;
    
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(0vw);

}

/* .handle_center_body{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    display: flex;
    flex-direction: column;
    gap: 20px;
} */

.update_bg h1 {
    font-family: sans-serif;
    font-size: 24px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.2px;
}

.update_bg > div {
    width: 100vw;
    height: 100%;
    position: absolute;
    background-position: left top;
    clip-path: inset(0 0 0 0);
    top: 0;
    right: 0;
}

.bg_1 > div, .bg_2 > div, .bg_3 > div {
    position: fixed;
    top: 50%;
    left: 50%;
    translate: -50% -50%;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

@keyframes width-to-zero {
    0% {
        width: 100vw;
    }
    100% {
        width: 0;
    }
}