.__container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#rating-icon {
    position: absolute;
    top: 0;
    left: 6px;
    height: 30px
}

#hotpackage {
    display: block;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 100%
}

#mouth {
    display: block;
    margin-top: 30px;
    position: absolute;
    -webkit-transform-origin: 0 100%;
    -moz-transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    -o-transform-origin: 0 100%;
    transform-origin: 0 100%
}

@media screen and (max-width: 700px) {
    #mouth {
        width: 60px  !important;
    }

    #foot {
        bottom: 0 !important;
        top: unset !important;
    }

    #mouth .back {
        top: 38.4% !important;
        left: -11% !important;
        z-index: 1 !important;
    }
}

#mouth.unready,
#mouth.getready {
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -ms-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-duration: .4s;
    -moz-animation-duration: .4s;
    -ms-animation-duration: .4s;
    animation-duration: .4s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    animation-fill-mode: forwards
}

#mouth.unready {
    -webkit-animation-name: closeup;
    -moz-animation-name: closeup;
    -ms-animation-name: closeup;
    animation-name: closeup
}

#mouth.getready {
    -webkit-animation-name: openup;
    -moz-animation-name: openup;
    -ms-animation-name: openup;
    animation-name: openup
}

#mouth img {
    position: absolute
}

#mouth .front {
    position: relative;
    z-index: 3
}

@media screen and (min-width:500px) {
    #mouth .front {
        width: 90px
    }
}

/* @media screen and (max-width:1000px) and (min-width: 700px) {
    #mouth .front {
        width: 106px
    }
}

@media screen and (max-width:1200px) and (min-width:1000px) {
    #mouth .front {
        width: 137.8px
    }
}

@media screen and (max-width:1600px) and (min-width:1200px) {
    #mouth .front {
        width: 169.6px
    }
} */

#mouth .tongue {
    top: 55%;
    left: -5%;
    z-index: 2
}

@media screen and (min-width:500px) {
    #mouth .tongue {
        width: 95px
    }
}

/* @media screen and (max-width:1200px) and (min-width:1000px) {
    #mouth .tongue {
        width: 123.5px
    }
}

@media screen and (max-width:1600px) and (min-width:1200px) {
    #mouth .tongue {
        width: 152px
    }
} */

#mouth .back {
    top: 45.4%;
    left: 9%;
    z-index: 1;
}

@media screen and (min-width:500px) {
    #mouth .back {
        width: 64.5px
    }
}

/* @media screen and (max-width:1200px) and (min-width:1000px) {
    #mouth .back {
        width: 83.85px
    }
}

@media screen and (max-width:1600px) and (min-width:1200px) {
    #mouth .back {
        width: 103.2px
    }
} */

#foot-contain {
    display: block;
    margin-left: 212px;
    position: relative;
    top: -55px;
    left: 0;
    height: 1199px;
    z-index: 10;
    touch-action: none;
}

@media screen and (min-width:500px) {
    #foot-contain {
        margin-left: 106px;
        height: 375px
    }
}

@media screen and (max-width: 700px) {
    #foot-contain {
        margin: 0;
        height: 100vh;
        width: 650px;
        top: unset;
        left: unset;
        bottom: unset;
        right: -80px;
        /* position: absolute;
        left: unset;
        top: unset;
        right: 50px;
        bottom: 0; */

        transform-origin: 50% 50vw;
    }
    
    #foot .foot {
        height: 410px;
    }
}

@media (max-width: 400px) {
    #foot .foot {
        height: unset;
    }
}

/* @media screen and (min-width: 700px) {
    #foot-contain {
        margin-left: 106px;
        height: 599.5px
    }
} */

/* @media screen and (max-width:1200px) and (min-width:1000px) {
    #foot-contain {
        margin-left: 137.8px;
        height: 779.35px
    }
}

@media screen and (max-width:1600px) and (min-width:1200px) {
    #foot-contain {
        margin-left: 169.6px;
        height: 959.2px
    }
} */

#foot {
    position: absolute;
    top: 0;
    right: 0;
    width: 1077px;
    height: 235px;
    z-index: 1
}

#foot .foot {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1
}

#foot .handle {
    background: transparent;
    display: block;
    position: absolute;
    bottom: 23%;
    right: 6%;
    width: 93%;
    height: 100%;
    z-index: 5;
    -webkit-transform-origin: 100% 100%;
    -moz-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    -o-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg)
}

#foot .handle .grip {
    background: transparent;
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 9
}

#foot .handle .mask {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 50%;
    z-index: 10
}

@media screen and (max-width:500px) {
    #foot {
        width: 300px
    }
}

@media screen and (min-width: 500px){
    #foot {
        width: 350px
    }
}

/* 
@media screen and (max-width:1000px) and (min-width: 700px) {
    #foot {
        width: 538.5px
    }
}

@media screen and (max-width:1200px) and (min-width:1000px) {
    #foot {
        width: 700.05px
    }
}

@media screen and (max-width:1600px) and (min-width:1200px) {
    #foot {
        width: 861.6px
    }
} */

.licky,
.lickylicky,
.lickylickylicky {
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-animation-name: yumyum;
    -moz-animation-name: yumyum;
    -ms-animation-name: yumyum;
    animation-name: yumyum;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out
}

.licky {
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -ms-animation-duration: 1s;
    animation-duration: 1s
}

.lickylicky {
    -webkit-animation-duration: .5s;
    -moz-animation-duration: .5s;
    -ms-animation-duration: .5s;
    animation-duration: .5s
}

.lickylickylicky {
    -webkit-animation-duration: .05s;
    -moz-animation-duration: .05s;
    -ms-animation-duration: .05s;
    animation-duration: .05s
}

@-webkit-keyframes openup {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(-2deg);
        -moz-transform: rotate(-2deg);
        -ms-transform: rotate(-2deg);
        -o-transform: rotate(-2deg);
        transform: rotate(-2deg)
    }
}

@-moz-keyframes openup {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(-2deg);
        -moz-transform: rotate(-2deg);
        -ms-transform: rotate(-2deg);
        -o-transform: rotate(-2deg);
        transform: rotate(-2deg)
    }
}

@-ms-keyframes openup {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(-2deg);
        -moz-transform: rotate(-2deg);
        -ms-transform: rotate(-2deg);
        -o-transform: rotate(-2deg);
        transform: rotate(-2deg)
    }
}

@keyframes openup {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(-2deg);
        -moz-transform: rotate(-2deg);
        -ms-transform: rotate(-2deg);
        -o-transform: rotate(-2deg);
        transform: rotate(-2deg)
    }
}

@-webkit-keyframes closeup {
    0% {
        -webkit-transform: rotate(-2deg);
        -moz-transform: rotate(-2deg);
        -ms-transform: rotate(-2deg);
        -o-transform: rotate(-2deg);
        transform: rotate(-2deg)
    }

    100% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg)
    }
}

@-moz-keyframes closeup {
    0% {
        -webkit-transform: rotate(-2deg);
        -moz-transform: rotate(-2deg);
        -ms-transform: rotate(-2deg);
        -o-transform: rotate(-2deg);
        transform: rotate(-2deg)
    }

    100% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg)
    }
}

@-ms-keyframes closeup {
    0% {
        -webkit-transform: rotate(-2deg);
        -moz-transform: rotate(-2deg);
        -ms-transform: rotate(-2deg);
        -o-transform: rotate(-2deg);
        transform: rotate(-2deg)
    }

    100% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg)
    }
}

@keyframes closeup {
    0% {
        -webkit-transform: rotate(-2deg);
        -moz-transform: rotate(-2deg);
        -ms-transform: rotate(-2deg);
        -o-transform: rotate(-2deg);
        transform: rotate(-2deg)
    }

    100% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg)
    }
}

@-webkit-keyframes yumyum {
    0% {
        left: 0;
        top: 55%
    }

    25% {
        top: 57%
    }

    50% {
        left: 12%
    }

    75% {
        top: 53%
    }

    100% {
        left: 0;
        top: 55%
    }
}

@-moz-keyframes yumyum {
    0% {
        left: 0;
        top: 55%
    }

    25% {
        top: 57%
    }

    50% {
        left: 12%
    }

    75% {
        top: 53%
    }

    100% {
        left: 0;
        top: 55%
    }
}

@-ms-keyframes yumyum {
    0% {
        left: 0;
        top: 55%
    }

    25% {
        top: 57%
    }

    50% {
        left: 12%
    }

    75% {
        top: 53%
    }

    100% {
        left: 0;
        top: 55%
    }
}

@keyframes yumyum {
    0% {
        left: 0;
        top: 55%
    }

    25% {
        top: 57%
    }

    50% {
        left: 12%
    }

    75% {
        top: 53%
    }

    100% {
        left: 0;
        top: 55%
    }
}

.text-btm-color-white {
    color: #FFF;
    font-family: Ambit;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.2px;
}