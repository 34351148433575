#mouth.ronaldo {
    display: block;
    margin-top: 30px;
    position: absolute;
    transform-origin: 0 100%;

    height: inherit;
    align-content: flex-end;
}

#mouth.ronaldo .front.ronaldo {
    bottom: 55px;
    position: relative;
    z-index: 3;
    height: 660px;
    width: 340px;
    left: -30px;
}

#mouth.ronaldo .tongue.ronaldo {
    top: 283px;
    left: 140px;
    z-index: 2;
    width: 58px;
    height: 37px;
}

#draggable-prop-container.ronaldo {
    display: block;
    margin-left: 212px;
    position: relative;
    top: 0;
    left: 0;
    height: 700px;
    z-index: 10
}

#draggable-prop-child.ronaldo  {
    position: absolute;
    bottom: 210px;
    right: 80px;
    width: 140px;
    height: 150px;
    z-index: 1
}

#draggable-prop-child.ronaldo .draggable-prop.ronaldo {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1
}

#draggable-prop-child.ronaldo .handle.ronaldo {
    background: transparent;
    display: block;
    position: absolute;
    bottom: 0%;
    right: 6%;
    width: 93%;
    height: 100%;
    z-index: 5;
    transform-origin: 100% 100%;
    transform: none;
}

#draggable-prop-child.ronaldo .handle.ronaldo .grip {
    background: transparent;
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 9
}

#draggable-prop-child.ronaldo .handle.ronaldo .mask {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 50%;
    z-index: 10
}

.licky_ronaldo,
.lickylicky_ronaldo,
.lickylickylicky_ronaldo {
    transform-origin: 0 0;
    animation-name: yumyum_ronaldo;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out
}

.licky_ronaldo {
    animation-duration: 1s
}

.lickylicky_ronaldo {
    animation-duration: .3s
}

.lickylickylicky_ronaldo {
    animation-duration: .1s
}

@keyframes yumyum_ronaldo {
    0% {
        left: 140px;
        top: 283px
    }

    25% {
        top: 283px
    }

    50% {
        left: 106px
    }

    75% {
        top: 283px
    }

    100% {
        left: 140px;
        top: 283px
    }
}

@keyframes yumyum_ronaldo_mobile {
    0% {
        left: 48px;
        top: 118px
    }

    25% {
        top: 118px
    }

    50% {
        left: 37px
    }

    75% {
        top: 118px
    }

    100% {
        left: 48px;
        top: 118px
    }
}

.desc-title.ronaldo {
    color: #6BE359;
    font-family: Ambit;
    font-size: 98px;
    font-style: normal;
    font-weight: 700;
    line-height: 0.95;
    letter-spacing: 4.9px;
    text-align: right;
}

@media (max-width: 1100px) {
    .desc-title.ronaldo {
        font-size: 65px;
    }
}

@media (max-width: 500px) {
    .desc-title.ronaldo {
        font-size: 40px;
        letter-spacing: 1px;
    }
}

@media (max-width: 900px) {
    .this_btm_text {
        display: none;
    }

    #draggable-prop-child.ronaldo {
        width: 80px;
        height: 85px;
        bottom: 15px;
        left: 70px;
    }

    #draggable-prop-container.ronaldo {
        margin-left: 106px;
        height: 300px
    }
    
    #mouth.ronaldo {
        margin-top: 10px;
    }

    #mouth.ronaldo .front.ronaldo {
        width: 120px;
        height: 232px;
        bottom: 0px;
        left: -10px;
    }

    #mouth.ronaldo .tongue.ronaldo {
        width: 25px;
        left: 48px;
        top: 118px;
        height: 15px;
    }

    .licky_ronaldo,
    .lickylicky_ronaldo,
    .lickylickylicky_ronaldo {
        transform-origin: 0 0;
        animation-name: yumyum_ronaldo_mobile;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out
    }
}