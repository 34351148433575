#mouth.snoop_dog {
    display: block;
    margin-top: 30px;
    position: absolute;
    transform-origin: 0 100%;

    height: inherit;
    align-content: flex-end;
}

#mouth.snoop_dog .front.snoop_dog {
    position: relative;
    z-index: 3;
    height: 555px;
    width: 297px;
    left: -45px;
    bottom: 0;
}

#mouth.snoop_dog .tongue.snoop_dog {
    top: 350px;
    left: 168px;
    z-index: 2;
    width: 65px;
    height: 40px
}

#draggable-prop-container.snoop_dog {
    display: block;
    margin-left: 212px;
    position: relative;
    top: 0;
    left: 0;
    height: 700px;
    z-index: 10
}

#draggable-prop-child.snoop_dog  {
    position: absolute;
    bottom: 180px;
    right: 70px;
    width: 220px;
    height: 215px;
    z-index: 1;
}

#draggable-prop-child.snoop_dog .draggable-prop.snoop_dog {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1
}

#draggable-prop-child.snoop_dog .handle.snoop_dog {
    background: transparent;
    display: block;
    position: absolute;
    bottom: 0%;
    right: 6%;
    width: 93%;
    height: 100%;
    z-index: 5;
    transform-origin: 100% 100%;
    transform: none;
}

#draggable-prop-child.snoop_dog .handle.snoop_dog .grip {
    background: transparent;
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 9
}

#draggable-prop-child.snoop_dog .handle.snoop_dog .mask {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 50%;
    z-index: 10
}

.licky_snoop_dog,
.lickylicky_snoop_dog,
.lickylickylicky_snoop_dog {
    transform-origin: 0 0;
    animation-name: yumyum_snoop_dog;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out
}

.licky_snoop_dog {
    animation-duration: 1s
}

.lickylicky_snoop_dog {
    animation-duration: .3s
}

.lickylickylicky_snoop_dog {
    animation-duration: .1s
}

@keyframes yumyum_snoop_dog {
    0% {
        left: 168px;
        top: 350px;
    }

    50% {
        left: 147px;
        top: 345px;
    }

    100% {
        left: 168px;
        top: 350px
    }
}

@keyframes yumyum_snoop_dog_mobile {
    0% {
        left: 55px;
        top: 140px
    }

    50% {
        left: 47px
    }

    100% {
        left: 55px;
        top: 140px
    }
}

.desc-title.snoop_dog {
    color: #F695FF;
    font-family: Chalkduster;
    font-size: 78px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 3.9px;
}

@media (max-width: 1100px) {
    .desc-title.snoop_dog {
        font-size: 60px;
        letter-spacing: 1px;
        line-height: 1.3;
    }
}

@media (max-width: 500px) {
    .desc-title.snoop_dog {
        font-size: 45px;
        letter-spacing: 1px;
    }
}


@media (max-width: 900px) {
    .this_btm_text {
        display: none;
    }

    #draggable-prop-child.snoop_dog {
        width: 95px;
        height: 28%;
        bottom: 0;
        left: 65px;
    }

    #draggable-prop-container.snoop_dog.snoop_dog {
        margin-left: 106px;
        height: 300px
    }
    
    #mouth.snoop_dog {
        margin-top: 10px;
    }

    #mouth.snoop_dog .front.snoop_dog {
        width: 120px;
        left: -30px;
        height: 224px;
        bottom: 0;
    }

    #mouth.snoop_dog .tongue.snoop_dog {
        width: 30px;
        left: 55px;
        top: 140px;
        height: 18px;
    }

    .licky_snoop_dog,
    .lickylicky_snoop_dog,
    .lickylickylicky_snoop_dog {
        transform-origin: 0 0;
        animation-name: yumyum_snoop_dog_mobile;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out
    }
}