#mouth.homelander {
    display: block;
    margin-top: 30px;
    position: absolute;
    transform-origin: 0 100%;
    
    height: inherit;
}

#mouth.homelander .front.homelander {
    position: relative;
    z-index: 3;
    height: 555px;
    width: 420px;
    left: -175px;
    bottom: 0;
}

#mouth.homelander .tongue.homelander {
    top: 375px;
    left: 130px;
    z-index: 2;
    width: 90px;
    height: 55px;
}

#draggable-prop-container.homelander {
    display: block;
    margin-left: 212px;
    position: relative;
    top: 0;
    left: 0;
    height: 700px;
    z-index: 10
}

#draggable-prop-child.homelander {
    position: absolute;
    bottom: 160px;
    right: 50px;
    width: 180px;
    height: 240px;
    z-index: 1
}

#draggable-prop-child.homelander .draggable-prop.homelander {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1
}

#draggable-prop-child.homelander .handle.homelander {
    background: transparent;
    display: block;
    position: absolute;
    bottom: 0%;
    right: 6%;
    width: 93%;
    height: 100%;
    z-index: 5;
    transform-origin: 100% 100%;
    transform: none;
}

#draggable-prop-child.homelander .handle.homelander .grip {
    background: transparent;
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 9
}

#draggable-prop-child.homelander .handle.homelander .mask {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 50%;
    z-index: 10
}

.licky_homelander,
.lickylicky_homelander,
.lickylickylicky_homelander {
    transform-origin: 0 0;
    animation-name: yumyum_homelander;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out
}

.licky_homelander {
    animation-duration: 1s
}

.lickylicky_homelander {
    animation-duration: .3s
}

.lickylickylicky_homelander {
    animation-duration: .1s
}

@keyframes yumyum_homelander {
    0% {
        left: 130px;
        top: 375px
    }

    25% {
        top: 375px
    }

    50% {
        left: 89px
    }

    75% {
        top: 367px
    }

    100% {
        left: 130px;
        top: 375px
    }
}


@keyframes yumyum_homelander_mobile {
    0% {
        left: 48px;
        top: 121px
    }

    25% {
        top: 121px
    }

    50% {
        left: 35px
    }

    75% {
        top: 117px
    }

    100% {
        left: 48px;
        top: 121px
    }
}

.desc-title.homelander {
    color: #ADEBFF;
    font-family: "Charlie don't surf!";
    font-size: 88px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 4.4px;
}

@media (max-width: 1100px) {
    .desc-title.homelander {
        font-size: 65px;
        letter-spacing: 1px;
        /* line-height: ; */
    }
}

@media (max-width: 500px) {
    .desc-title.homelander {
        font-size: 45px;
        letter-spacing: 1px;
    }
}


@media (max-width: 900px) {
    .this_btm_text {
        display: none;
    }

    #draggable-prop-child.homelander {
        width: 80px;
        height: 80px;
        bottom: 0;
        left: 58px;
    }

    #draggable-prop-container.homelander {
        margin-left: 106px;
        height: 400px
    }

    #mouth.homelander .front.homelander {
        /* height: 400px; */
        width: 280px;
        left: -42px;

        height: inherit;
        top: 0px;
    }

    #mouth.homelander .tongue.homelander {
        top: 121px;
        left: 48px;
        width: 24px;
        height: 15px;
    }
    
    .licky_homelander,
    .lickylicky_homelander,
    .lickylickylicky_homelander {
        transform-origin: 0 0;
        animation-name: yumyum_homelander_mobile;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out
    }
}