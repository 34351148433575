.container{
    width: 100vw;
    max-width: 100%;
    height: 100vh;

    position: relative;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
}

.people_image {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    height: 22rem;
    object-fit: cover;
    object-position: top;
}

.main_section {
    width: 100%;
    height: 100%;

    background: url(../../assets/bg_shapes.svg), #FC4F66;
    background-position: center;
    padding-top: 80px;
    transition: all 0.3s linear;
    transition-delay: 0;
    background-blend-mode: color-dodge;
}

.text_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 0 5px;
}

.heading {
    color: #FFF;
    font-family: Ambit-700;
    font-size: 48px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2.4px;

    text-align: center;
}

.sub_heading {
    color: #FFF;
    text-align: center;
    font-family: Ambit-600;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
}

.buttons_hldr {
    display: flex;
    align-items: center;
    gap: 10px;
}

.btn_holder {
    display: flex;
    height: 48px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;

    border-radius: 8px;
    border: 1px solid #C72152;
    background: #FFE7E7;


    color: #FC4F66;
    font-family: Ambit-600;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    transition: all 0.3s ease-in-out;
}

.btn_holder:hover{
    box-shadow: 2px 2px 0px 0px #000000;
    transform: translate(-2px, -2px);
    transition: all 0.3s ease-in-out;
}

@media (max-width: 1000px) {
    .people_image {
        height: 18rem;
    }
}

@media (max-width: 800px) and (min-height: 800px) {
    .people_image {
        height: 27rem;
    }
}

@media (max-width: 1300px) {
    .people_image {
        left: -435px !important;
    }
}

@media (max-width: 1150px) {
    .people_image {
        left: -550px !important;
    }
}

@media (max-width: 900px) {
    .people_image {
        left: -660px !important;
    }
}

@media (max-width: 650px) {
    .people_image {
        left: -750px !important;
    }
}

@media (max-width: 550px) {
    .people_image {
        left: -800px !important;
    }
}

@media (max-width: 450px) {
    .people_image {
        left: -850px !important;
    }
}

@media (max-width: 350px) {
    .people_image {
        left: -900px !important;
    }
}

@media (max-width: 500px) {
    .heading {
        font-size: 32px;
        letter-spacing: 1px;
    }

    .sub_heading {
        font-size: 17px;
        letter-spacing: 0.8px;
    }
}

@media (max-width: 400px) {
    .btn_holder {
        height: 40px;
        padding: 10px;
        gap: 6px;

        font-size: 18px;
        letter-spacing: 0.8px;
    }
}