.footer {
    height: 90px;
    width: 100%;
    border: 2px solid #3A3A3A;
    background: #FFE7E7;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 40px;
    background: url("https://res.cloudinary.com/seimutants/image/upload/v1724666582/rrgml6eeytmeomecvgn6.png"), #FFE7E7;
    position: relative;
}

.social_links {
    display: flex;
    align-items: center;
    gap: 20px;
}

.btn_holder img {
    height: 26px;
}

.logo_wrpr {
    display: flex;
    align-items: center;
    gap: 15px;
}

.logo_text {
    color: #FC4F66;
    font-family: Ambit-900;
    font-size: 24px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: uppercase;
}

@media (max-width: 700px) {
    .footer {
        padding: 0 15px;
    }

    .center_logo {
        display: none;
    }

    .social_links {
        gap: 10px;
    }

    .btn_holder img {
        height: 20px;
    }    
}

@media (max-width: 400px) {
    .footer {
        height: 65px;
    }

    .logo_wrpr {
        gap: 10px;
    }

    .logo_wrpr img {
        width: 30px;
        height: 30px;
    }

    .logo_text {
        font-size: 20px;
    }
}