.container {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 60px 120px;
    padding-top: 80px;
    padding-bottom: 100px;
    justify-content: center;
    align-items: center;

    background: url('https://res.cloudinary.com/seimutants/image/upload/v1724701308/exopvgmg9ves4ddnwjre.webp');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
}

.heading_abst h1{
    position: absolute;
    top: 12px;
    left: 50%;
    transform: translateX(-50%);

    color: #FC4F66;
    font-family: Ambit-700;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.8px;
}

.slider_root{
    width: 100%;
    height: 100%;

    border-radius: 18.928px;

    box-shadow: 6px 6px 0px 0px #000;
    position: relative;

    display: flex;
    align-items: center;

    transition: all 0.3s ease-in-out;
    scroll-behavior: smooth;
    overflow: hidden;
}

.slide_page {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 18.928px;

    object-fit: cover;
}

.main_img {
    width: 100%;
    height: 100%;
    border-radius: 18.928px;

    object-fit: cover;
    object-position: left;
}

.left_arrow_pos{
    position: absolute;
    top: 50%;
    left: -5px;
    transform: translateY(-50%);
}

.right_arrow_pos{
    position: absolute;
    top: 50%;
    right: -5px;
    transform: translateY(-50%);
}

.slides_mark_wrpr{
    position: absolute;

    width: 100%;
    padding: 0 120px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    z-index: 2;

    left: 0;
    right: 0;
    bottom: 40px;
}

.slide_mark{
    height: 8px;
    width: 100%;
    border-radius: 20px;
    opacity: 0.18;
    background: #FC4F66;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);

    transition: all 0.3s ease-in-out;
}

.slide_mark:first-child {
    opacity: 1;
}

.abst_close {
    position: absolute;

    top: 20px;
    right: 20px;
}

@media screen and (min-width: 200px) and (max-width: 900px) {
    .container {
        padding: 50px;
        padding-bottom: 25px;
    }
    
    .heading_abst h1 {
        font-size: 20px;
    }
}

@media screen and (min-width: 450px) and (max-width: 900px) {
    .container {
        padding: 70px;
        padding-bottom: 35px;
    }
    
    .heading_abst h1 {
        font-size: 24px;
    }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
    .container {
        padding: 80px;
        padding-bottom: 45px;
    }

    .heading_abst h1 {
        font-size: 30px;
    }
}

@media screen and (min-width: 750px) and (max-width: 900px) {
    .container {
        padding: 90px;
        padding-bottom: 55px;
    }
 
    .heading_abst h1 {
        font-size: 37px;
    }
}

@media (max-width: 900px) {
    .right_arrow_pos {
        right: -22px;
    }

    .left_arrow_pos {
        left: -22px;
    }

    .right_arrow_pos img, .left_arrow_pos img {
        width: 130px;
    }

    .slides_mark_wrpr {
        display: none;
    }

    .abst_close {
        width: 27px;
    }
}