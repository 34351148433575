#mouth.messi {
    display: block;
    margin-top: 30px;
    position: absolute;
    transform-origin: 0 100%;

    height: inherit;
    align-content: flex-end;
}

#mouth.messi .front.messi {
    position: relative;
    z-index: 3;
    left: -70px;
    height: 555px;
    width: 360px;
    bottom: 0;
}

#mouth.messi .tongue.messi {
    top: 345px;
    left: 180px;
    z-index: 2;
    width: 70px;
    height: 50px;
}

#draggable-prop-container.messi {
    display: block;
    margin-left: 212px;
    position: relative;
    top: 0;
    left: 0;
    height: 800px;
    z-index: 10
}

#draggable-prop-child.messi  {
    position: absolute;
    bottom: 165px;
    right: 65px;
    width: 140px;
    height: 245px;
    z-index: 1;
}

#draggable-prop-child.messi .draggable-prop.messi {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1
}

#draggable-prop-child.messi .handle.messi {
    background: transparent;
    display: block;
    position: absolute;
    bottom: 0%;
    right: 6%;
    width: 93%;
    height: 100%;
    z-index: 5;
    transform-origin: 100% 100%;
    transform: none;
}

#draggable-prop-child.messi .handle.messi .grip {
    background: transparent;
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 9
}

#draggable-prop-child.messi .handle.messi .mask {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 50%;
    z-index: 10
}

.licky_messi,
.lickylicky_messi,
.lickylickylicky_messi {
    transform-origin: 0 0;
    animation-name: yumyum_messi;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out
}

.licky_messi {
    animation-duration: 1s
}

.lickylicky_messi {
    animation-duration: .3s
}

.lickylickylicky_messi {
    animation-duration: .1s
}

@keyframes yumyum_messi {
    0% {
        left: 180px;
        top: 345px
    }

    25% {
        top: 345px
    }

    50% {
        left: 147.5px
    }

    75% {
        top: 345px
    }

    100% {
        left: 180px;
        top: 345px
    }
}

@keyframes yumyum_messi_mobile {
    0% {
        left: 63px;
        top: 129px
    }

    25% {
        top: 129px
    }

    50% {
        left: 55px
    }

    75% {
        top: 129px
    }

    100% {
        left: 63px;
        top: 129px
    }
}

.desc-title.messi {
    color: #ECECEC;
    font-family: Ambit;
    font-size: 98px;
    font-style: normal;
    font-weight: 700;
    line-height: 0.9;
    letter-spacing: 4.9px;
}


@media (max-width: 1100px) {
    .desc-title.messi {
        font-size: 80px;
        /* letter-spacing: 1px; */
        /* line-height: ; */
    }
}

@media (max-width: 500px) {
    .desc-title.messi {
        font-size: 45px;
        letter-spacing: 1px;
    }
}

div#\#relpto.messi {
    width: 135px;
}

@media (max-width: 900px) {
    .this_btm_text {
        display: none;
    }

    #draggable-prop-child.messi {
        width: 60px;
        height: 85px;
        bottom: 0px;
        left: 85px;
    }

    #draggable-prop-container.messi {
        margin-left: 106px;
        height: 300px
    }
    
    #mouth.messi {
        margin-top: 10px;
    }

    #mouth.messi .front.messi {
        height: 207px;
        width: 135px;
        left: -30px;
        bottom: 0;
    }

    #mouth.messi .tongue.messi {
        width: 30px;
        left: 63px;
        top: 129px;
        height: 21px;
    }
    
    .licky_messi,
    .lickylicky_messi,
    .lickylickylicky_messi {
        transform-origin: 0 0;
        animation-name: yumyum_messi_mobile;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out
    }
}