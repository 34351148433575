.__container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#hotpackage {
    display: block;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: inherit;
}

div#\#relpto.biden {
    width: 300px;
}

#mouth.unready,
#mouth.getready {
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-duration: .4s;
    animation-fill-mode: forwards
}

#mouth.unready {
    animation-name: closeup
}

#mouth.getready {
    animation-name: openup
}

#mouth img {
    position: absolute
}

.desc-title {
    position: absolute;
    bottom: -22px;
    right: -12px;
    z-index: 2;
}

@media (max-width: 1100px) {
    .desc-title {
        right: 0;
        bottom: -18px;
    }
}

@media (max-width: 500px) {
    .desc-title {
        right: 0;
        bottom: -10px;
    }
}

@keyframes openup {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(-2deg)
    }
}

@keyframes closeup {
    0% {
        transform: rotate(-2deg)
    }

    100% {
        transform: rotate(0deg)
    }
}

.text-btm-color-white {
    color: #FFF;
    font-family: Ambit;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.2px;
}

@media screen and (max-width: 900px) {
    #mouth.mouthits {
        width: 120px !important;
    }

    #draggable-prop-container {
        margin: 0 !important;
        /* height: 100vh !important; */
        /* width: 100vw !important; */
        top: unset !important;
        left: unset !important;
        bottom: unset;
        right: unset;

        transform-origin: 50% 50vw;
    }
    
    #draggable-prop-container .draggable-prop-child {
        height: 410px !important;
    }
}

@media (max-width: 400px) {
    #foot .foot {
        height: unset;
    }
}