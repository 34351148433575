#mouth.biden {
    display: block;
    margin-top: 30px;
    position: absolute;
    transform-origin: 0 100%;

    height: inherit;
    align-content: flex-end;
}

#mouth.biden .front.biden {
    position: relative;
    z-index: 3;
    height: 555px;
    width: 445px;
    left: -175px;
    bottom: 0;
}

#mouth.biden .tongue.biden {
    top: 233px;
    left: 150px;
    z-index: 2;
    width: 84px;
    height: 65px;
}

#draggable-prop-container.biden {
    display: block;
    margin-left: 212px;
    position: relative;
    top: 0;
    left: 0;
    z-index: 10
}

#draggable-prop-child.biden {
    position: absolute;
    bottom: 26%;
    right: 20px;
    width: 200px;
    height: 265px;
    z-index: 1
}

#draggable-prop-child.biden .draggable-prop.biden {
    position: absolute;
    bottom: 0;
    width: 150px;
    z-index: 1
}

#draggable-prop-child.biden .handle.biden {
    background: transparent;
    display: block;
    position: absolute;
    bottom: 0%;
    right: 6%;
    width: 93%;
    height: 100%;
    z-index: 5;
    transform-origin: 100% 100%;
    transform: none;
}

#draggable-prop-child.biden .handle.biden .grip {
    background: transparent;
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 9
}

#draggable-prop-child.biden .handle.biden .mask {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 50%;
    z-index: 10
}

.licky_biden,
.lickylicky_biden,
.lickylickylicky_biden {
    transform-origin: 0 0;
    animation-name: yumyum_biden;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out
}

.licky_biden {
    animation-duration: 1s
}

.lickylicky_biden {
    animation-duration: .3s
}

.lickylickylicky_biden {
    animation-duration: .1s
}

.desc-title.biden {
    color: #F7C3D1;
    font-family: Ambit;
    font-size: 108px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 5.4px;
}

@media (max-width: 1100px) {
    .desc-title.biden {
        font-size: 65px;
        letter-spacing: 1px;
        /* line-height: ; */
    }
}

@media (max-width: 500px) {
    .desc-title.biden {
        font-size: 45px;
        letter-spacing: 1px;
    }
}

@keyframes yumyum_biden {
    0% {
        left: 150px;
        top: 233px
    }

    25% {
        top: 233px
    }

    50% {
        left: 123px
    }

    75% {
        top: 233px
    }

    100% {
        left: 150px;
        top: 233px
    }
}

@keyframes yumyum_biden_mobile {
    0% {
        left: 64px;
        top: 91px
    }

    25% {
        top: 91px
    }

    50% {
        left: 53px
    }

    75% {
        top: 91px
    }

    100% {
        left: 64px;
        top: 91px
    }
}

@media screen and (max-width:900px) {
    #mouth.biden .front.biden {
        width: 170px;
        left: -65px;
        height: auto;
        top: 0px;
    }

    #draggable-prop-child.biden {
        width: 60px;
        height: 90px;
        bottom: 0;
        left: 75px;
    }

    #draggable-prop-container.biden {
        margin-left: 106px;
        height: 350px;
    }

    #mouth.biden {
        margin-top: 5px;
    }

    #mouth.biden .tongue.biden {
        width: 28px;
        top: 91px;
        left: 64px;
        height: 21px;
    }
    
    .licky_biden,
    .lickylicky_biden,
    .lickylickylicky_biden {
        transform-origin: 0 0;
        animation-name: yumyum_biden_mobile;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out
    }
}