.container{
    display: flex;
    flex-direction: column;
    gap: 40px;

    padding: 70px 80px;
}

.buy_section {
    width: 100%;
    border-radius: 16px;
    background: url('https://res.cloudinary.com/seimutants/image/upload/v1724556801/nawdz3f3yxbapsbqhb2a.webp');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 36px 57px;

    display: flex;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    gap: 200px;
}

.cntnt_wrpr {
    display: flex;
    flex-direction: column;
    gap: 34px;
    width: 100%;
}

.heading {
    color: #FC4F66;
    text-shadow: 0px 4px 0px #000;
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: #000;
    font-family: Ambit-700;
    font-size: 54px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 2.7px;
}

.token_wrpr {
    display: flex;
    flex-direction: column;
    gap: 12px;

    width: 100%;
    max-width: 600px;
}

.token_input_wrpr {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.token_number {
    width: 100%;
    display: flex;
    padding: 16px 18px;
    /* justify-content: center; */
    align-items: center;
    gap: 10px;

    border-radius: 8px;
    border: 2px solid #000;
    background: #FFF;

    color: #FC4F66;
    font-family: Ambit-700;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3.64px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: scroll;
}

.token_number::-webkit-scrollbar {
    display: none;
}

.copy_btn {
    display: flex;
    height: 48px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 8px;
    border: 2px solid #000;
    background: #FFF;
}

.buy_btn {
    height: 40px;
    padding: 20px;
    padding-top: 22px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #C72152;
    background: #EF2A64;
    box-shadow: 4px 4px 0px 0px #000;
    color: #FFF;
    font-family: Ambit-600;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.8px;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.3s ease-in-out;
}

.buy_btn:hover {
    box-shadow: 8px 8px 0px 0px #000;
    transform: translate(-4px, -4px);
    transition: all 0.3s ease-in-out;
}

.wallets_wrpr {
    display: grid;
    grid-template-columns: repeat(2, 170px);
    gap: 32px;
}

.wallet {
    width: 170px;
    height: 170px;
    flex-shrink: 0;
    border-radius: 8.851px;
    background: #FFF;
    box-shadow: 3.319px 4.425px 0px 0px #000;
    transition: all 0.3s ease-in-out;

    display: flex;
    align-items: center;
    justify-content: center;
}

.wallet:hover {
    box-shadow: 8px 8px 0px 0px #000;
    transform: translate(-4px, -4px);
    transition: all 0.3s ease-in-out;
}

.lick_lick_tongue {
    position: absolute;
    left: -30px;
    bottom: -60px;
    width: 650px;
}

@media (max-width: 1400px) {
    .buy_section {
        gap: 20px;
    }   
}

@media (max-width: 1300px) {
    .container {
        padding: 40px;
    }   
}

@media (max-width: 1150px) {
    .buy_section {
        padding: 25px 20px;
    }

    .wallets_wrpr {
        display: grid;
        grid-template-columns: repeat(2, 140px);
        column-gap: 15px;
        row-gap: 15px;
    }

    .wallet {
        width: 140px;
        height: 140px;
    }

    .cntnt_wrpr {
        gap: 25px;
    }
    
    .heading {
        font-size: 35px;
    }

    .token_number {
        padding: 10px 20px;
    }

    .copy_btn {
        padding: 10px 20px;
        height: unset;
    }

    .buy_btn {
        height: unset;
        padding: 10px;
    }

    .lick_lick_tongue {
        width: 500px;
        bottom: -40px;
    }
}

@media (max-width: 1050px) {
    .buy_section {
        gap: 40px;
    }

    .token_wrpr {
        width: 400px;
    }

    .lick_lick_tongue {
        width: 400px;
        bottom: -40px;
    }
}

@media (max-width: 880px) {
    .buy_section {
        flex-direction: column;
        padding-bottom: 100px;
    }

    .token_wrpr {
        width: 100%;
        max-width: unset;
    }

    .wallets_wrpr {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: auto;
    }
}

@media (max-width: 700px) {
    .container {
        padding: 50px 20px;
    }
}

@media (max-width: 500px) {
    .container {
        padding: 50px 20px;
    }

    .heading {
        font-size: 30px;
    }
    
    .cntnt_wrpr {
        gap: 20px;
    }

    .wallets_wrpr {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin: 0;
    }

    .wallet {
        width: 100%;
        height: 120px;
    }

    .lick_lick_tongue {
        width: 300px;
        bottom: -25px;
        left: -10px;
    }
}

@media (max-width: 400px) {
    .lick_lick_tongue {
        width: 100%;
        left: -15px;
        bottom: -30px;
    }
}