@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: Ambit;
    src: url(./assets/fonts/Ambit\ Regular.otf);
}
@font-face {
    font-family: Ambit-600;
    src: url(./assets/fonts/Ambit-600.otf);
}
@font-face {
    font-family: Ambit-700;
    src: url(./assets/fonts/Ambit-700.otf);
}
@font-face {
    font-family: Ambit-900;
    src: url(./assets/fonts/Ambit-900.otf);
}
@font-face {
    font-family: "Charlie don't surf!";
    src: url(./assets/fonts/Charlie\ don\'t\ surf.ttf);
}
@font-face {
    font-family: Chalkduster;
    src: url(./assets/fonts/Chalkduster.ttf);
}

html, body{ 
    width: 100%;
    height: 100%;
    overflow-x: hidden;

    scroll-behavior: smooth;
}

*::-webkit-scrollbar {
    background: #ffe7e7;
    width: 4px;
}

*::-webkit-scrollbar-track {
    background: none;
}

*::-webkit-scrollbar-thumb {
    background: #222;
    border-radius: 12px;
}

*::-webkit-scrollbar-button {
    width: 2px;
    height: 2px;
}