.nav {
    height: 72px;
    width: 100%;
    background: #ffe7e7;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
}

.link {
    display: flex;
    align-items: center;
    gap: 15px;
}

.logo_text {
    color: #FC4F66;
    font-family: Ambit-900;
    font-size: 24px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: uppercase;
}

.button_holders {
    display: flex;
    align-items: center;
    gap: 12px;
}

.outlined_btn {
    display: inline-flex;
    height: 40px;
    padding: 0 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;

    border-radius: 8px;
    border: 1px solid #FC4F66;

    color: #FC4F66;
    font-family: Ambit-600;
    font-size: 16px;
    font-style: normal;
    letter-spacing: 0.8px;
    transition: all 0.3s ease-in-out;
}

.outlined_btn:hover {
    box-shadow: 2px 2px 0px 0px #EF2A64;
    transform: translate(-2px, -2px);
    transition: all 0.3s ease-in-out;
}

.filled_btn {
    display: inline-flex;
    height: 40px;
    padding: 0 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    
    border-radius: 8px;
    border: 1px solid #C72152;
    background: #EF2A64;

    color: #FFF;
    font-family: Ambit-600;
    font-size: 16px;
    font-style: normal;
    letter-spacing: 0.8px;
    transition: all 0.3s ease-in-out;
}

.filled_btn:hover{
    box-shadow: 2px 2px 0px 0px #000;
    transform: translate(-2px, -2px);
    transition: all 0.3s ease-in-out;
}

@media (max-width: 600px) {
    .logo_text {
        display: none;
    }
}

@media (max-width: 500px) {
    .nav {
        padding: 0 15px;
    }

    .filled_btn {
        display: none;
    }
}