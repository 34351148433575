.loader_cntnt{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    width: 0%;
    height: 100%;

    z-index: 10000;

    display: flex;
    align-items: flex-start;

    animation: expandSize;
    animation-timing-function: ease-in-out;
    animation-duration: 2.5s;

    overflow: hidden;
}

@keyframes expandSize {
    0%{
        width: 0%;
    }
    50%{
        width: 100%;
    }
    99.999%{
        width: 100%;
    }
    100%{
        width: 0%;
    }
}

._portion:first-child {
    width: 0%;
    animation: slideExpand;
    animation-timing-function: ease-in-out;
    animation-duration: 2.5s;
}

@keyframes slideExpand {
    0%{
        width: 100%;
    }
    50%{
        transform: translateX(0%);
    }
    99.99%{
        width: 100%;
    }
    100%{
        transform: translateX(-100%);
        width: 0%;
    }
}

._portion:last-child {
    width: 0%;
    animation: rightslideExpand;
    animation-timing-function: ease-in-out;
    animation-duration: 2.5s;
}

@keyframes rightslideExpand {
    0%{
        width: 100%;
    }
    50%{
        transform: translateX(0%);
    }
    99.99%{
        width: 100%;
    }
    100%{
        transform: translateX(100%);
        width: 0%;
    }
}

._portion{
    width: 50%;
    height: 100%;

    background: #FEBFCD;
    display: flex;
    align-items: flex-start;
}

.mid_color{
    width: 33%;
    height: 100%;
    background-color: #AD648E;
}

.mid_color_darker{
    width: 33%;
    height: 100%;
    background-color: #390031;
}