#mouth.trump {
    display: block;
    margin-top: 30px;
    position: absolute;
    transform-origin: 0 100%;

    height: inherit;
    align-content: flex-end;
}

#mouth.trump .front.trump {
    position: relative;
    z-index: 3;
    height: 650px;
    width: 325px;
    left: -125px;
    bottom: 45px;
}

#mouth.trump .tongue.trump {
    top: 250px;
    left: 118px;
    z-index: 2;
    width: 84px;
    height: 56px;
}

#draggable-prop-container.trump {
    display: block;
    margin-left: 212px;
    position: relative;
    top: 0;
    left: 0;
    height: 600px;
    z-index: 10
}

#draggable-prop-child.trump {
    position: absolute;
    bottom: 205px;
    right: 95px;
    width: 270px;
    height: 150px;
    z-index: 1;
}

#draggable-prop-child.trump .draggable-prop.trump {
    position: absolute;
    bottom: 0;
    width: 270px;
    z-index: 1
}

#draggable-prop-child.trump .handle.trump {
    background: transparent;
    display: block;
    position: absolute;
    bottom: 0%;
    right: 6%;
    width: 93%;
    height: 100%;
    z-index: 5;
    transform-origin: 100% 100%;
    transform: none;
}

#draggable-prop-child.trump .handle.trump .grip {
    background: transparent;
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 9
}

#draggable-prop-child.trump .handle.trump .mask {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 50%;
    z-index: 10
}

.licky_trump,
.lickylicky_trump,
.lickylickylicky_trump {
    transform-origin: 0 0;
    animation-name: yumyum_trump;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out
}

.licky_trump {
    animation-duration: 1s
}

.lickylicky_trump {
    animation-duration: .3s
}

.lickylickylicky_trump {
    animation-duration: .1s
}

@keyframes yumyum_trump {
    0% {
        left: 118px;
        top: 250px;
    }

    50% {
        left: 98.5px;
        top: 266px;
    }

    100% {
        left: 118px;
        top: 250px;
    }
}


@keyframes yumyum_trump_mobile {
    0% {
        left: 57px;
        top: 110px;
    }

    50% {
        left: 48px;
        top: 115px;
    }

    100% {
        left: 57px;
        top: 110px;
    }
}

.desc-title.trump {
    bottom: 0px;
}

@media (max-width: 1100px) {
    .desc-title.trump {
        width: 200px;
    }
}

@media (max-width: 500px) {
    .desc-title.trump {
        width: 180px;
    }
}

@media (max-width: 900px) {
    .this_btm_text {
        display: none;
    }

    #draggable-prop-child.trump {
        width: 100px;
        height: 90px;
        bottom: 40px;
        left: 55px;
    }

    #draggable-prop-container.trump {
        margin-left: 106px;
        height: 300px
    }

    #mouth.trump {
        margin-top: 10px;
    }

    #mouth.trump .front.trump {
        width: 320px;

        left: -35px;
        height: inherit;
        top: 0px;
    }

    #mouth.trump .tongue.trump {
        width: 29px;
        left: 57px;
        top: 110px;
        height: 20px;
    }
    
    .licky_trump,
    .lickylicky_trump,
    .lickylickylicky_trump {
        transform-origin: 0 0;
        animation-name: yumyum_trump_mobile;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out
    }
}