.container{
    display: flex;
    flex-direction: column;
    gap: 40px;

    padding: 70px 80px;
}

.heading {
    color: #FFF;
    font-family: Ambit-700;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 2.4px;
}

.explore_showcase {
    display: grid;
    width: 100%;
    grid-template-columns:repeat(3, 1fr);
    justify-content: space-between;
    grid-template-rows: auto;
    column-gap: 20px;
    row-gap: 40px;
}

.grid_box {
    height: 280px;
    flex-shrink: 0;
    box-shadow: 3.975px 3.975px 0px 0px #000;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.grid_box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.grid_box:hover {
    box-shadow: 8px 8px 0px 0px #000;
    transform: translate(-4px, -4px);
    transition: all 0.3s ease-in-out;
}

.grid_box:hover .main_text_wrpr{
    transform: translate(-50%, 0px);
    transition: all 0.3s ease-in-out;
}

.grid_text {
    color: #FFF;
    font-family: Ambit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.6px;
    line-height: 1;
}

.main_text_wrpr {
    transform: translate(-50%, 40px);
    max-width: 180px;
    width: 45%;
    height: 40px;
}

@media (max-width: 1300px) {
    .container {
        padding: 40px;
    }   
}

@media (max-width: 1400px) {
    .grid_box {
        height: 230px;
    }
}

@media (max-width: 1200px) {
    .grid_box {
        height: 180px;
    }
}

@media (max-width: 1000px) {
    .explore_showcase {
        grid-template-columns:repeat(2, 1fr);
    }

    .grid_box {
        height: 200px;
    }
}

@media (max-width: 700px) {
    .container {
        padding: 50px 20px;
    }

    .grid_box {
        height: 160px;
    }
}

@media (max-width: 500px) {
    .container {
        padding: 50px 20px;
    }

    .heading {
        font-size: 32px;
        letter-spacing: 1px;
    }

    .explore_showcase {
        grid-template-columns:repeat(1, 100%);
    }

    .grid_box {
        height: 180px;
    }
}

@media (max-width: 400px) {
    .grid_box {
        height: 150px;
    }
}