.container {
    width: 100%;
    height: 430px;
    flex-shrink: 0;
    background: #0C0002;

    padding: 35px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
    
    overflow: hidden;
}

.imgs_circular_wrpr {
    display: flex;
    align-items: center;
    gap: 70px;

    position: relative;
    top: 140%;

    /* animation-name: circle-rotate;
    animation-duration: 10s;
    animation-timing-function: linear;
    animation-iteration-count: infinite; */
}

.imgs_circular_wrpr:nth-child(2) {
    top: 75%;
    animation-duration: 13s;
}

/* .imgs_circular_wrpr:first-child:before {
    content: '';
    width: 1400px;
    height: 1400px;
    position: sticky;
    left: 0;
    top: 0;
    border-radius: 50%;
    border: 1px solid #FFF;
} */

.abst_circle {
    width: 1400px;
    height: 1400px;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #FFF;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

@keyframes circle-rotate {
    0% {
        rotate: 0deg;
    }
    100% {

        rotate: 360deg;
    }
}

.lick_img_wrpr {
    position: absolute;

    width: 150px;
    height: 150px;
    flex-shrink: 0;
    border-radius: 50%;
    border: 1px solid #FFF;
    padding: 9px;

    top: 50%;
    left: 50%;

    z-index: 2;
}

.heading {
    color: #FC4F66;
    text-shadow: 0px 4px 0px #000;
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: #000;
    font-family: Ambit-700;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.7px;
    z-index: 3;
}

.description {
    color: #FFF;
    text-shadow: 0px 4px 0px #000;
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: #000;
    font-family: Ambit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.8px;
    z-index: 3;
}

.btn_wrpr {
    display: flex;
    width: 600px;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;

    border-radius: 61px;
    border: 1px solid #FFF;
    z-index: 3;
}

.btn {
    display: flex;
    height: 40px;
    padding: 12px 12px 12px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    border-radius: 58px;
    background: #FFF;
    box-shadow: 4px 4px 0px 0px #000;

    color: #FC5067;
    font-family: Ambit-600;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.8px;
    z-index: 3;
}

@media (max-width: 1000px) {
    .container {
        gap: 15px;
        height: 400px;
    }

    .heading {
        font-size: 24px;
    }

    .description {
        font-size: 14px;
    }

    .btn_wrpr {
        width: 400px;
    }   

    .lick_img_wrpr {
        width: 120px;
        height: 120px;
    }

    .imgs_circular_wrpr {
        zoom: 0.95;
    }
}

@media (max-width: 400px) {
    .container {
        height: 370px;
    }

    .btn_wrpr {
        width: 300px;
    }

    .heading {
        font-size: 24px;
    }

    .description {
        font-size: 12px;
        text-align: center;
    }

    .imgs_circular_wrpr {
        zoom: 0.85;
    }
}